import * as loadQueueHelper from '../document/load-queue'
import storage from '../document/web-storage'

function sendCustomAttributes () {
  try {
    if (typeof newrelic === 'object') {
      const abCookieValue = storage.cookie.get('sunweb_ab')
      if (abCookieValue) {
        newrelic.setCustomAttribute('sunweb_ab', abCookieValue)
      }
    }
  } catch (e) {
    if (window.newrelic) {
      window.newrelic.noticeError(e)
    }
  }
}

loadQueueHelper.add(function () {
  sendCustomAttributes()
})
